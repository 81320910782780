<template>
  <div class="item-card" :class="{ 'item-card--inactive': !isActive }">
    <div class="item-card__menu" v-if="menu.visibility">
      <slot name="menu"></slot>
    </div>

    <!-- info -->
    <section class="item-card__content">
      <button
        v-if="hasMenu"
        class="item-card__content__menu-trigger"
        @click="toggleMenuVisibility()"
      >
        <fa-icon icon="ellipsis-h"></fa-icon>
      </button>

      <slot></slot>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.item-card {
  @apply p-4 shadow relative;

  &.item-card--inactive {
    @apply border-gray-100;
  }
}

.item-card__menu {
  @apply absolute p-2 mb-2 top-7 right-3 z-20 max-w-screen-sm;
  @apply bg-white text-right shadow-lg;
  @apply grid grid-cols-1;

  & > button {
    @apply text-right mb-2;

    &:last-child {
      @apply mb-0;
    }

    span {
      @apply mr-2;
    }
  }
}

.item-card__content {
  @apply bg-white;
}

.item-card__content__menu-trigger {
  @apply absolute z-10 top-0 right-0 p-2 px-4;
}

.item-card__content__item {
  @apply absolute z-10 -top-5 right-2;
}
</style>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      menu: { visibility: false },
    };
  },
  props: {
    isActive: {
      default: true,
      type: Boolean,
    },
    hasMenu: {
      default: true,
      type: Boolean,
    },
  },
  methods: {
    toggleMenuVisibility() {
      if (this.menu.visibility === false) {
        // current visibility false means this item card's menu is hidde.,
        // then to prevent multiple display of item card's menu,
        // all menu must be hidden first
        EventBus.$emit("item-card.menu.hide");
      }
      // then only this item card's menu is visible
      this.menu.visibility = !this.menu.visibility;
    },
    hideMenu() {
      this.menu.visibility = false;
    },
  },
  mounted() {
    EventBus.$on("item-card.menu.hide", this.hideMenu);
  },
};
</script>